@use '@angular/material' as mat;

@import 'core/settings/settings';
@import 'core/tools/tools';

/*
 * By default 'x-high-quality'
 */

$h1: mat.define-typography-level(
  $font-family: get-font('secondary'),
  $font-weight: 900,
  $font-size: calc-size(get-font-size('x-high-quality', 'h1')),
  $line-height: 1,
  $letter-spacing: normal,
);

$h2: mat.define-typography-level(
  $font-family: get-font('secondary'),
  $font-weight: 600,
  $font-size: calc-size(get-font-size('x-high-quality', 'h2')),
  $line-height: 1,
  $letter-spacing: normal,
);

$h3: mat.define-typography-level(
  $font-family: get-font('secondary'),
  $font-weight: 600,
  $font-size: calc-size(get-font-size('x-high-quality', 'h3')),
  $line-height: 1,
  $letter-spacing: normal,
);

$h4: mat.define-typography-level(
  $font-family: get-font('secondary'),
  $font-weight: 600,
  $font-size: calc-size(get-font-size('x-high-quality', 'h4')),
  $line-height: 1,
  $letter-spacing: normal,
);

$text: mat.define-typography-level(
  $font-family: get-font('primary'),
  $font-weight: 400,
  $font-size: calc-size(get-font-size('x-high-quality', 'text')),
  $line-height: 1,
  $letter-spacing: normal,
);

$caption: mat.define-typography-level(
  $font-family: get-font('primary'),
  $font-weight: 400,
  $font-size: calc-size(get-font-size('x-high-quality', 'caption')),
  $line-height: 1,
  $letter-spacing: normal,
);

$button: mat.define-typography-level(
  $font-family: get-font('primary'),
  $font-weight: 400,
  $font-size: calc-size(get-font-size('x-high-quality', 'button')),
  $line-height: 1,
  $letter-spacing: normal,
);
