
@use 'palette' as palette;
@use 'typography' as typography;

@use '@angular/material' as mat;
@import '@angular/material/theming';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

/* 
 * Define a light theme
 */

$light-primary: mat.define-palette(palette.$primary, 500);
$light-accent: mat.define-palette(palette.$primary, 500);
$light-warn: mat.define-palette(palette.$warn, 500);

/* 
 * Define a typography
 */

 $my-typography: mat.define-legacy-typography-config(
  $display-1: typography.$h1,
  $headline: typography.$h1,
  $title: typography.$h2,
  $subheading-1: typography.$h3,
  $subheading-2: typography.$h4,
  $body-1: typography.$text,
  $body-2: typography.$text,
  $caption: typography.$caption,
);

/*
 * Set up application theme
 */

$light-theme: mat.define-light-theme((
  color: (
    primary: $light-primary,
    accent: $light-accent,
    warn: $light-warn,
  ),
  typography: $my-typography,
));

@include mat.all-legacy-component-themes($light-theme);
